<template>
  <section>
    <div class="header-page">
      <div class="container">
        <h1 data-aos="fade-up">Editoras</h1>
        <p class="subtitulo" data-aos="fade-down">Sua editora 100% digital.</p>
      </div>
    </div>

    <div class="container page">
      <div class="text" data-aos="fade-down">
        <p>
          Integre os materiais didáticos da sua instituição a uma versão digital
          completa, repleta de recursos interativos e com mais de 5 mil jogos
          educacionais compatíveis com a BNCC, além de ferramentas de estudo,
          incentivos e personalização visual da sua marca. Uma oferta atrativa
          para impulsionar as vendas da sua editora. Com a nossa plataforma você
          personaliza o portal com a sua identidade e ainda conta com relatórios
          estratégicos para acompanhar a usabilidade de seus clientes.
        </p>
        <div class="valores-empresa">
          <div class="valor-item">
            <h2>Principais benefícios:</h2>
            <p>
              <br />• Altamente escalável; <br />• Espaço biblioteca digital
              para upload de materiais; <br />• Ferramenta de autoria de jogos;
              <br />• Assessoria de tecnologia educacional; <br />• Design
              segmentado por ciclo e idade; <br />• Fonte de receita recorrente
              e fidelização de clientes; <br />• O melhor custo-benefício do
              mercado; <br />• Customização em acordo com a sua marca ou com a
              nossa; <br />• Relatórios de gestão inteligente.
            </p>
          </div>
          <div class="valor-item">
            <h2>Ideal para:</h2>
            <p>
              <br />
              • Empresas que publicam materiais didáticos; <br />
              • Editoras de diferentes portes; <br />
              • Editoras do segmento de ensino básico.
            </p>
          </div>
        </div>
      </div>
      <div class="imagem">
        <img
          src="@/assets/img/editora.png"
          alt="editora"
          class="foto-page"
          data-aos="fade-left"
        />
        <img
          src="@/assets/icons/detalhe_01.svg"
          class="detalhe"
          data-aos="fade-right"
        />
      </div>
    </div>
    <div class="container segmentos">
      <Carrosel :cards="cards" :segmentos="true">
        <div>
          <h2>Conheça <b>outros segmentos</b></h2>
          <p class="sub">
            Conheça mais sobre outros segmentos e descubra como podemos atuar na
            sua empresa.
          </p>
        </div>
      </Carrosel>
    </div>
  </section>
</template>

<script>
import Carrosel from "@/components/Carrosel";

export default {
  name: "editora",
  data() {
    return {
      cards: [
        {
          path: "icons/icon-distribuidores.svg",
          title: "Distribuidores",
          date: "",
          text: "A oferta digital mais criativa para o seu portfólio.",
          link: "/distribuidores",
        },
        {
          path: "icons/icon-sistema.svg",
          title: "Sistema de ensino",
          date: "",
          text: "A solução mais prática para fidelizar alunos, professores e gestores ao seu sistema de ensino.",
          link: "/sistema-de-ensino",
        },
        {
          path: "icons/icon-prefeitura.svg",
          title: "Prefeituras e Governos",
          date: "",
          text: "Sem essa de versão light. A mesma tecnologia para rede pública e privada.",
          link: "/prefeituras-e-governos",
        },
        {
          path: "icons/icon-empresas.svg",
          title: "Empresas e Promoções",
          date: "",
          text: "Marketing inteligente que fideliza e aumenta as suas vendas.",
          link: "/empresas-e-promocoes",
        },
        {
          path: "icons/icon-cooporativo.svg",
          title: "Corporativo/RH",
          date: "",
          text: "Estimule o desenvolvimento e potencial de seus colaboradores.",
          link: "/coportativo-rh",
        },
        {
          path: "icons/icon-cursos.svg",
          title: "Cursos e Ensino Técnico",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/cursos-e-ensino-tecnico",
        },
        {
          path: "icons/icon-universidade.svg",
          title: "Universidade",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/universidades",
        },
      ],
    };
  },
  components: {
    Carrosel,
  },
  created() {
    document.title = "Atheva | Editoras";
  },
};
</script>
